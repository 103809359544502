<template>
  <div v-if="content" class="container">
    <!-- <div class="row row-cols-12">
      <div class="col-11 logo">
        <img
          src="/images/logo.jpeg"
          alt="Ifero 2.0"
          class="d-inline-block align-text-top"
        />
      </div>
      <div class="col-1">
        <img :src="`${qr}`" class="d-inline-block align-text-top" />
      </div>
    </div> -->
    <div class="row row-cols-2">
      <div class="col logo">
        <img
          src="/images/logo.jpeg"
          alt="Ifero 2.0"
          class="d-inline-block align-text-top"
        />
      </div>
      <!-- <Header :content="content" :type="$route.params.type" /> -->
      <div class="row row-cols-12">
        <div class="col-9 logo">
        </div>
      <div class="col-3">
        <img  v-if="qr" :src="`${qr}`" class="d-inline-block" />
      </div>
      </div>
      <div class="col-6 small ps-0">
        <b>PRIMERO RENT A CAR DOO BEOGRAD</b><br />
        Vase Pelagića 38<br />
        11040 Beograd<br />
        SERBIA<br />
        Tel: +381 11 785 27 88; 785 27 89<br />
        Fax: +381 11 212 9486<br />
        PIB (Tax ID number): <b>SR102449114</b><br />
        Matični broj / Register Co. number: <b>17453416</b><br />
        Tekući račun (RSD) / Bank account (RSD): <b>160-305895-11</b><br />
        SWIFT: <b>DBDBRSBG</b><br />
        IBAN: <b>RS35160005010027484921</b><br />
        BancaIntesa AD, Milentija Popovića 7B, 11070 Beograd<br />
        <div class="fs-6 text-success">
          {{ content.ref_doc_nr ? "Po dokumentu:" : "" }}
          <b>{{ content.ref_doc_nr ? content.ref_doc_nr : "" }}</b>
        </div>
      </div>
      <div class="col-6 small">
        <div class="small ps-3 pb-2">
          <div class="row">
            <div class="col-6 p-0 border">
              <strong v-if="$route.params.type == 'Normal'"
                >RAČUN / INVOICE</strong
              >
              <strong v-if="$route.params.type == 'Proform'"
                >PREDRAČUN / <nobr>PROFORMA INVOICE</nobr></strong
              >
              <strong v-if="$route.params.type == 'Advance'"
                >AVANSNI RAČUN / <nobr>ADVANCE INVOICE</nobr></strong
              >
            </div>
            <div class="col-6 p-0 border">
              <strong>{{ content.doc_nr ?? "" }}</strong>
            </div>
          </div>
          <div class="row">
            <div class="col-6 p-0" v-if="$route.params.type == 'Proform'">Datum valute / Value date</div>
            <div class="col-6 p-0" v-else>Datum prometa / Delivery date</div>
            <div class="col-6 p-0">
              {{ dateFormat(content.doc_date ?? "") }}
            </div>
          </div>
          <div class="row">
            <div class="col-6 p-0" v-if="$route.params.type == 'Proform'">Datum Predračuna / Proforma Invoice date</div>
            <div class="col-6 p-0" v-else>Datum računa / Invoice date</div>
            <div class="col-6 p-0 tc">
              {{ dateFormat(content.doc_date ?? "") }}
            </div>
          </div>
          <div class="row" v-if="!content.selling_id">
            <div class="col-6 p-0">Broj vaučera / Voucher no</div>
            <div class="col-6 p-0">
              {{ content.voucher_num }}
            </div>
          </div>
          <div class="row" v-if="!content.selling_id">
            <div class="col-6 p-0">Outbound Broj / Outbound no</div>
            <div class="col-6 p-0">
              {{ content.reservation_num }}
            </div>
          </div>
          <div class="row">
            <div class="col-6 p-0">Mesto računa / Place</div>
            <div class="col-6 p-0">
              {{ content.Station ? content.Station.city : "" }}
            </div>
          </div>
          <div class="row">
            <div class="col-6 p-0">PO broj / PO number</div>
            <div class="col-6 p-0">
              {{ content.po_num }}
            </div>
          </div>
        </div>
        <div class="small border ps-1">
          <b>Račun plaća / Invoice to:</b>
          <div class="">
            {{ content.Customer ? content.Customer.name : "" }}<br />
            {{ content.Customer ? content.Customer.address : "" }}<br />
            {{ content.Customer ? content.Customer.zip : "" }}
            {{ content.Customer ? content.Customer.city : "" }},
            {{ content.Customer ? content.Customer.country : "" }}<br />
            PIB: {{ content.Customer ? content.Customer.pib : "" }}<br />
            Matični broj: {{ content.Customer ? content.Customer.mb : ""
            }}<br />
            {{
              content.Customer && content.Customer.bank_account
                ? `Tekući račun: ${content.Customer.bank_account}`
                : ""
            }}<br />
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      v-if="content.additional && content.additional.registration_nr"
    >
      <div class="col-6 small ps-3">
        <div class="row">
          <div class="col-6 p-0">Ugovor o najmu broj:</div>
          <div class="col-6 p-0">
            {{ content.additional ? content.additional.contract_nr : "" }}
          </div>
        </div>
        <div class="row">
          <div class="col-6 p-0">Vozač</div>
          <div class="col-6 p-0">
            {{ content.additional ? content.additional.driver : "" }}
          </div>
        </div>
        <div class="row">
          <div class="col-6 p-0">Vozilo</div>
          <div class="col-6 p-0">
            {{ content.additional ? content.additional.model : "" }}
          </div>
        </div>
        <div class="row">
          <div class="col-6 p-0">Broj rergistracije</div>
          <div class="col-6 p-0">
            {{ content.additional ? content.additional.registration_nr : "" }}
          </div>
        </div>
        <div class="row">
          <div class="col-6 p-0">Datum preuzimanja</div>
          <div class="col-6 p-0">
            {{ content.additional ? content.additional.pickup_date : "" }}
          </div>
        </div>
        <div class="row">
          <div class="col-6 p-0">Datum vraćanja</div>
          <div class="col-6 p-0">
            {{ content.additional ? content.additional.return_date : "" }}
          </div>
        </div>
        <div class="row">
          <div class="col-6 p-0">Pređena kilometraža</div>
          <div class="col-6 p-0">
            {{ content.additional ? content.additional.km : "" }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col p-0">
        <table class="table small table-sm">
          <thead>
            <tr class="table-secondary small">
              <th scope="col">R.B.</th>
              <th scope="col" class="text-center">SADRŽAJ / CONTENTS</th>
              <th scope="col" class="text-end">RSD</th>
              <th scope="col" class="text-end" v-if="$route.params.exc == '1'">
                EUR
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="small"
              v-for="(item, index) in content.invoice_items"
              :key="index"
            >
              <th scope="row">{{ getNext(index) }}</th>
              <td
                v-if="
                  item['article'] &&
                  item['article'].default_for == 'Normal' &&
                  item['tax'] == 0 &&
                  content['additional']
                "
              >
                {{
                  item["article"]
                    ? `${item["article"].name} po računu broj ${content["additional"].incoming_invoice}`
                    : ""
                }}
              </td>
              <td v-else>{{ item["article"] ? item["article"].name : "" }}</td>
              <td class="text-end">
                {{ valuePrefix
                }}{{ item["price"] ? numFormat(item["price"]) : "" }}
              </td>
              <td class="text-end" v-if="$route.params.exc == '1'">
                {{ valuePrefix
                }}{{ item["price_exc"] ? numFormat(item["price_exc"]) : "" }}
              </td>
            </tr>
            <tr class="small" v-for="(item, index) in totalSum" :key="index">
              <th scope="row">
                {{ getNext() }}
              </th>
              <td v-if="index == 'taxs' || index == 'osnovica'">
                {{ item["label"] ? item["label"] : "" }}
              </td>
              <td v-else>
                <strong>{{ item["label"] ? item["label"] : "" }}</strong>
              </td>
              <td class="text-end">
                <strong v-if="index != 'taxs' && index != 'osnovica'"
                  >{{ valuePrefix
                  }}{{ item["sum"] ? numFormat(item["sum"]) : "" }}</strong
                >
                <span v-else
                  >{{ valuePrefix
                  }}{{ item["sum"] ? numFormat(item["sum"]) : "" }}</span
                >
              </td>
              <td class="text-end" v-if="$route.params.exc == '1'">
                <strong v-if="index != 'taxs' && index != 'osnovica'"
                  >{{ valuePrefix
                  }}{{
                    item["sum_exc"] ? numFormat(item["sum_exc"]) : ""
                  }}</strong
                >
                <span v-else
                  >{{ valuePrefix
                  }}{{
                    item["sum_exc"] ? numFormat(item["sum_exc"]) : ""
                  }}</span
                >
              </td>
            </tr>
            <tr
              class="small"
              v-for="(item, index) in content.invoice_payments"
              :key="index"
            >
              <th scope="row">
                {{ getNext() }}
              </th>
              <td>Uplata {{ item["statement_nr"] }}</td>
              <td class="text-end">
                {{ valuePrefix
                }}{{ item["payment"] ? numFormat(item["payment"]) : "" }}
              </td>
              <td class="text-end" v-if="$route.params.exc == '1'">
                {{ valuePrefix
                }}{{
                  item["payment_exc"] ? numFormat(item["payment_exc"]) : ""
                }}
              </td>
            </tr>
            <tr class="small" v-for="(item, index) in paymentSum" :key="index">
              <th scope="row">
                {{ getNext() }}
              </th>
              <td>
                <strong>{{ item["label"] ? item["label"] : "" }}</strong>
              </td>
              <td class="text-end">
                <strong
                  >{{ valuePrefix
                  }}{{ item["sum"] ? numFormat(item["sum"]) : "" }}</strong
                >
              </td>
              <td class="text-end" v-if="$route.params.exc == '1'">
                <strong
                  >{{ valuePrefix
                  }}{{
                    item["sum_exc"] ? numFormat(item["sum_exc"]) : ""
                  }}</strong
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col small">
          Rok plaćanja / Due date:
          {{ dateFormat(content.val_date ?? "") }}
          <p>{{
            (content.cession_id ?( content.comment ?? "").replaceAll('\n', '').replaceAll('; ', '\n').trim() : content.comment ?? "").replaceAll(
              "$datum_kursa",
              dateFormat(content.exchange_date)
            )
          }}</p>
      </div>
    </div>

    <div class="row">
      <div v-if="content.Reservation && isKR">
        <div class="row">
          <div class="col small py-0">
            *** Nadoknada iz R.B. 1 nije predmet oporezivanja po članu 17. stav
            4 tačka 2. zakona o PDV-u
          </div>
        </div>
        <div class="row">
          <div class="col small py-0">
            <strong class="border px-5 py-1"
              >Uplatiti na račun preduzeća 160-305895-11 u Banca Intesa Beograd
            </strong>
          </div>
        </div>
        <div class="row">
          <div class="col small py-0">Ugovor o najmu / RA (u prilogu)</div>
        </div>
        <div class="row">
          <div class="col small py-0">
            Vozač: {{ content.Reservation.driver_name }}
          </div>
        </div>
      </div>

      <div v-if="content.cession_id">
        <div class=" md-2">
          <div class="col small">
            Molimo da uplatu izvršite na tekući račun broj {{ seller.tr2 }} 
          </div>
        </div>
        <div class="md-2">
          <div class="col small">
            Dinarski iznos ove fakture služi za utvrđivanje PDV-a. Plaćanje se vrši po ugovornim obavezama, po srednjem kursu NBS na dan {{ dateFormat(content.exchange_date) }}.
          </div>
        </div>
      </div>

      <div class="col small">
        Ovaj dokument je izrađen automatskom obradom podataka i važeći je bez
        pečata i potpisa.
      </div>
      <div class="md-2">
      <div class="col small" v-if="content.Selling && content.Selling.ino_buyer">
        U iznos fakture uključeni su svi troškovi / All costs are included in the invoice amount.
      </div>
    </div>
    </div>

    <div class="row">
      <div class="col small" v-if="content.invoice_status == 'New'">
        NAPOMENA: Dokument je nezavršen.
      </div>
      <div class="col small" v-if="content.invoice_status == 'Deleted'">
        NAPOMENA: Dokument je storniran.
      </div>
    </div>
    <div class="row">
      <div class="col text-start">
        <div class="p-3 sig text-center small">
          Dokument izradio/la<br />
          {{ currentUser.email }} <br />
          {{ currentUser.firstname }} {{ currentUser.lastname }}
        </div>
      </div>
    </div>
    <div v-if="isKR && paymentSum['sum']['sum'] < 0">
      <hr />
      <div>
        <strong>
          Na osnovu računa izvršiće se povraćaj sredstava po članu 42 stav 3
          Zakona o porezu na dodatu vrednost</strong
        >
        <ol type="1" class="p-3">
          <li>
            Potvrđujemo
            <strong
              >da smo primili konačni račun broj {{ content.doc_nr }}</strong
            >
            sa datumom
            <strong
              >{{ dateFormat(content.doc_date ?? "") }}. i da smo na osnovu
              člana 21 stav 2 Zakona o porezu na dodatu vrednost umanjili
              osnovicu kao i naš ulazni PDV,</strong
            >
            adekvatno iskazanom na konačnom računu
            <strong>Iznos {{ paymentSum["taxs2"]["sum"] }}</strong> dana
            _____________
          </li>
          <li>Obaveštavamo Vas da nismo u sistemu PDV-a</li>
          <li>
            Obaveštavamo Vas da smo u sistemu PDV-a, ali da nemamo prava na
            odbitak prethodnog PDV-a
          </li>
        </ol>
      </div>
      <div class="row">
        <div class="col-4 small text-center">
          Kupac - odgovorno lice<br />__________________________
        </div>
        <div class="col-4 small text-center">MP</div>
        <div class="col-4 small text-center">
          Mesto i datum <br />__________________________
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InvoiceService from "../../../services/invoice.service";
import QRCodeService from "../../../services/qr-nbs";
import dateUtils from "../../../utils/date-common";
import utils from "../../../utils/common";
export default {
  name: "documents",
  components: {},
  //   props: ["content"],
  methods: {
    dateFormat(date) {
      return dateUtils.formatDate(date);
    },
    formatNumber(num) { return utils.toNumberFormat(Number.parseFloat(num)) },
    numFormat(num) {
      return utils.toNumberFormat(Number.parseFloat(num));
    },
    getNext(index) {
      if (index >= 0) this.rowCount["row"] = index;
      this.rowCount["row"]++;
      return this.rowCount["row"];
    },
    getSums() {
      for (const item of this.content.invoice_items) {
        this.totalSum["medjuzbir"] = {
          label: "Medjuzbir/Subtotal",
          sum: Number.parseFloat(
            Number.parseFloat(
              this.totalSum["medjuzbir"] ? this.totalSum["medjuzbir"]["sum"] : 0
            ) +
              item.price * item.quantity
          ).toFixed(2),
          sum_exc: Number.parseFloat(
            Number.parseFloat(
              this.totalSum["medjuzbir"]
                ? this.totalSum["medjuzbir"]["sum_exc"]
                : 0
            ) +
              item.price_exc * item.quantity
          ).toFixed(2),
        };
        this.totalSum["osnovica"] = {
          label: "Osnovica PDV / VAT",
          sum: Number.parseFloat(
            Number.parseFloat(
              this.totalSum["osnovica"] ? this.totalSum["osnovica"]["sum"] : 0
            ) + Number.parseFloat(item.price)
          ).toFixed(2),
          sum_exc: Number.parseFloat(
            Number.parseFloat(
              this.totalSum["osnovica"]
                ? this.totalSum["osnovica"]["sum_exc"]
                : 0
            ) + Number.parseFloat(item.price_exc)
          ).toFixed(2),
        };
        this.totalSum["taxs"] = {
          label: "PDV / VAT",
          sum: Number.parseFloat(
            Number.parseFloat(
              this.totalSum["taxs"] ? this.totalSum["taxs"]["sum"] : 0
            ) + Number.parseFloat(item.tax)
          ).toFixed(2),
          sum_exc: Number.parseFloat(
            Number.parseFloat(
              this.totalSum["taxs"] ? this.totalSum["taxs"]["sum_exc"] : 0
            ) + Number.parseFloat(item.tax_exc)
          ).toFixed(2),
        };
      }
      this.totalSum["sum"] = {
        label: "Ukupno za uplatu / Total to be paid",
        sum: Number.parseFloat(
          Number.parseFloat(this.totalSum["osnovica"]["sum"]) +
            Number.parseFloat(this.totalSum["taxs"]["sum"])
        ).toFixed(2),
        sum_exc: Number.parseFloat(
          Number.parseFloat(this.totalSum["osnovica"]["sum_exc"]) +
            Number.parseFloat(this.totalSum["taxs"]["sum_exc"])
        ).toFixed(2),
      };
    },
    getPaymentsSums() {
      if (this.content.invoice_payments.length == 0) {
        delete this.paymentSum.uplaceno;
        delete this.paymentSum.osnovica;
        delete this.paymentSum.taxs;
        delete this.paymentSum.sum;
        return;
      }
      for (const item of this.content.invoice_payments) {
        console.log(item);
        this.paymentSum["uplaceno"] = {
          label: "Ukupno uplaceno",
          sum: Number.parseFloat(
            Number.parseFloat(
              this.paymentSum["uplaceno"]
                ? this.paymentSum["uplaceno"]["sum"]
                : 0
            ) + Number.parseFloat(item.payment)
          ).toFixed(2),
          sum_exc: Number.parseFloat(
            Number.parseFloat(
              this.paymentSum["uplaceno"]
                ? this.paymentSum["uplaceno"]["sum_exc"]
                : 0
            ) + Number.parseFloat(item.payment_exc)
          ).toFixed(2),
        };
        if (this.isKR) {
          this.paymentSum["osnovica"] = {
            label: "Osnovica PDV / VAT po avansu",
            sum: Number.parseFloat(
              Number.parseFloat(
                this.paymentSum["osnovica"]
                  ? this.paymentSum["osnovica"]["sum"]
                  : 0
              ) +
                item.payment / 1.2
            ).toFixed(2),
            sum_exc: Number.parseFloat(
              Number.parseFloat(
                this.paymentSum["osnovica"]
                  ? this.paymentSum["osnovica"]["sum_exc"]
                  : 0
              ) +
                item.payment_exc / 1.2
            ).toFixed(2),
          };
          this.paymentSum["taxs"] = {
            label: "PDV / VAT 20% po avansu",
            sum: Number.parseFloat(
              Number.parseFloat(
                this.paymentSum["taxs"] ? this.paymentSum["taxs"]["sum"] : 0
              ) +
                (item.payment - (item.payment / 1.2))
            ).toFixed(2),
            sum_exc: Number.parseFloat(
              Number.parseFloat(
                this.paymentSum["taxs"] ? this.paymentSum["taxs"]["sum_exc"] : 0
              ) +
                (item.payment_exc - (item.payment_exc / 1.2))
            ).toFixed(2),
          };
        }
      }
      this.paymentSum["sum"] = {
        label: "Za uplatu / To be paid",
        sum: Number.parseFloat(
          this.totalSum["sum"]["sum"] - this.paymentSum["uplaceno"]["sum"]
        ).toFixed(2),
        sum_exc: Number.parseFloat(
          this.totalSum["sum"]["sum_exc"] -
            this.paymentSum["uplaceno"]["sum_exc"]
        ).toFixed(2),
      };
      if (this.isKR && this.paymentSum["sum"]["sum"] < 0) {
        this.paymentSum["sum"]["label"] = "Ostatak";
        this.paymentSum["osnovica2"] = {
          label: "* Osnovica",
          sum: Number.parseFloat(
            Number.parseFloat(
              this.paymentSum["osnovica2"]
                ? this.paymentSum["osnovica2"]["sum"]
                : 0
            ) +
              this.paymentSum["sum"]["sum"] / 1.2
          ).toFixed(2),
          sum_exc: Number.parseFloat(
            Number.parseFloat(
              this.paymentSum["osnovica2"]
                ? this.paymentSum["osnovica2"]["sum_exc"]
                : 0
            ) +
              this.paymentSum["sum"]["sum_exc"] / 1.2
          ).toFixed(2),
        };
        this.paymentSum["taxs2"] = {
          label: "* PDV / VAT 20%",
          sum: Number.parseFloat(
            Number.parseFloat(
              this.paymentSum["taxs2"] ? this.paymentSum["taxs2"]["sum"] : 0
            ) +
              Number.parseFloat(this.paymentSum["sum"]["sum"]) -
              this.paymentSum["sum"]["sum"] / 1.2
          ).toFixed(2),
          sum_exc: Number.parseFloat(
            Number.parseFloat(
              this.paymentSum["taxs2"] ? this.paymentSum["taxs2"]["sum_exc"] : 0
            ) +
              Number.parseFloat(this.paymentSum["sum"]["sum_exc"]) -
              this.paymentSum["sum"]["sum_exc"] / 1.2
          ).toFixed(2),
        };
        //Iznos za uplatu/povraćaj
        this.paymentSum["sum2"] = {
          ...this.paymentSum["sum"],
          label: "Iznos za uplatu/povraćaj",
        };
      }
    },
  },
  data() {
    return {
      content: null,
      // rowCount: 0,
      isKR: false,
      qr: null,
    };
  },
  computed: {
    seller() {
      return this.$store.state.app.seller;
    },
    rowCount() {
      return {
        row: 0,
      };
    },
    totalSum() {
      return {
        medjuzbir: 0,
        osnovica: 0,
        taxs: 0,
        sum: 0,
      };
    },
    paymentSum() {
      return {
        // osnovica: 0,
        // taxs: 0,
        // uplaceno: 0,
        // sum: 0,
      };
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    valuePrefix() {
      return this.content.invoice_status == "Deleted" ? "-" : "";
    },
  },
  mounted() {
    InvoiceService.get(this.$route.params.id).then(
      async (response) => {
        this.content = response.data;
        this.isKR =
          response.data.doc_nr && response.data.doc_nr.endsWith("/KR");
        this.getSums();
        this.getPaymentsSums();
        if (this.content.invoice_type != "Advance") {
          let i = 0;
          if (this.paymentSum.sum) {
            i = Number.parseFloat(this.paymentSum.sum.sum).toLocaleString("sr-RS", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: false,
            });
          } else {
            i = Number.parseFloat(this.totalSum.sum.sum).toLocaleString("sr-RS", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: false,
            });
          }
          // console.log(this.content.Customer.type);
          const sf = this.content.Customer.type != 3 ? 221 : 289;
          console.log(Number("0"), Number("0") > 0);
          if (i > "0") {
            const qr = await QRCodeService.post(`K:PR|V:01|C:1|R:160000000030589511|N:PRIMERO RENT A CAR DOO BEOGRAD\nVase Pelagića 38 11040 Beograd Srbija|I:RSD${i}|SF:${sf}|RO:00${this.content.doc_nr.replaceAll('/', '-')}`);
            this.qr = qr.data.i ? `data:image/jpg;base64,${qr.data.i}`: null;
          }
        }
        setTimeout(function () {
          window.document.close();
          window.document.title = `${response.data.doc_nr ?? response.data.id}`;
          window.focus();
          window.print();
          window.onafterprint = window.close();
        }, 300);
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>

<style scoped>
.container {
  /* border: 1px solid gray; */
  width: 800px;
  height: 900px;
}
.container .row > .col,
.container .row > [class^="col-"] {
  padding-top: 0.0rem;
  padding-bottom: 0.75rem;
  /* background-color: rgba(39, 41, 43, 0.03); */
  /* border: 1px solid rgba(39, 41, 43, 0.1); */
}

.logo {
  padding: 0 !important;
}

img {
  height: 120px;
}

.sig {
  float: right;
}
tr {
  vertical-align: middle;
}

.tc {
  display: flex;
  text-align: center;
  align-items: center;
}
</style>